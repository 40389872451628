import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Header from '../components/header';
import SEO, { SeoModel } from '../components/seo';
import Footer from '../components/footer';
import { FlitsenData } from '../templates/flitsen';

interface FlitsenPageProps {
  location: Location,
  data: {
    allFlitsendataJson: {
      edges: { node: FlitsenData }[]
    },
    flitsenBannerImage: any
  }
}

export const flitsenPageQuery = graphql`
  query FlitsenPageQuery {
    allFlitsendataJson(sort: {fields: fields___slug, order: DESC}) {
      edges {
        node {
          fields {
            slug
          }
          volume
          volumeYear
          volumeNumber
          publicationDate
          publicationDateText
          bannerImage
          coverImage
          file
          fileLoRes
        }
      }
    }
  }
`;

export default class FlitsenPage extends React.Component<FlitsenPageProps, {}> {

  public render() {
    const {
      allFlitsendataJson
    } = this.props.data;

    const seo: SeoModel = {
      title: 'Jubal Flitsen',
      description: 'Lees hier digitaal het roemruchte clubblad van Jubal Dordrecht!',
    };

    return (
      <div id="top" className="d-flex flex-column w-100 h-100 mainBackground overflow-hidden">
        <Header />
        <SEO model={seo} />
        <div className="mt-5 overflow-hidden" />
        <section id="events" className={`bg-light pt-4 pb-0 w-100 overflow-hidden`}>
          <div className="m-3 mb-5 px-3 text-center">
            <h1 className="mt-5 pt-4">Jubal Flitsen</h1>
            <p>Lees hier digitaal het roemruchte clubblad van Jubal!</p>

            {allFlitsendataJson.edges.map(({node}) => node.volumeYear).filter((value, index, self) => self.indexOf(value) === index).map(volumeYear =>
            <div key={volumeYear} className="row pt-3">
              <div className="col-12">
                <div className="d-flex px-2 align-items-center border-bottom border-primary">
                  <h2 className="mr-3">{volumeYear}</h2>
                  <h4><small>Jaargang {allFlitsendataJson.edges.find(x => x.node.volumeYear === volumeYear)?.node.volume}</small></h4>
                </div>
              </div>
              {allFlitsendataJson.edges.filter(({node}) => node.volumeYear === volumeYear).map(({ node }) => (
                <div key={`${node.fields.slug}`} className={`col-12 col-lg-6 d-flex justify-content-center align-items-center`}>
                  <Link to={`/flitsen/${node.fields.slug}`} className="deco-none">
                    <img
                      className={`my-3`}
                      src={node.coverImage}
                      width="100%"
                      title={this.getFlitsenTitle(node)}
                      alt={this.getFlitsenTitle(node)}
                    />
                  </Link>
                </div>
              ))}
            </div>
            )}
          </div>
        </section>
        <Footer isFixedBottom={false} isSitemapHidden={false} location={this.props.location} />
      </div>
    );
  }

  private getFlitsenTitle(node: FlitsenData): string | undefined {
    return `Jubal Flitsen ${node.volumeYear} No. ${node.volumeNumber} - ${node.publicationDateText}`;
  }
}
